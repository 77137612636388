var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menuList"},[_c('li',[_c('p',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
        el: '#About',
        offset: -60,
      }),expression:"{\n        el: '#About',\n        offset: -60,\n      }"}],staticClass:"AboutLink"},[_vm._v(" ABOUT ")])]),_c('li',[_c('p',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
        el: '#Work',
        offset: -60,
      }),expression:"{\n        el: '#Work',\n        offset: -60,\n      }"}],staticClass:"WorkLink"},[_vm._v(" WORKS ")])]),_c('li',[_c('p',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
        el: '#Skill',
        offset: -60,
      }),expression:"{\n        el: '#Skill',\n        offset: -60,\n      }"}],staticClass:"SkillLink"},[_vm._v(" SKILL ")])]),_c('li',[_c('p',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
        el: '#Contact',
        offset: -60,
      }),expression:"{\n        el: '#Contact',\n        offset: -60,\n      }"}],staticClass:"ContactLink"},[_vm._v(" CONTACT ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }